
  
.why-work-with-us h2 {
    padding-bottom: 30px;
    font-size: 2.5rem;
}
  
  .why-work-with-us .card{
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: 1px solid #FFFFFF;
    background: transparent;
  }
  .why-work-with-us .container::after{
    content: url(../../Assets/images/asteroid-work.svg);
    position: absolute;
    top: 3px;
    left: -85px;
    transform: rotate(-91deg);
  }
  .why-work-with-us .container::before{
    content: url(../../Assets/images/satelite.svg);
    position: absolute;
    position: absolute;
    top: 367px;
    left: -16px;
    transform: rotate(-91deg);
  }
 
  .why-work-with-us p.card-text {
    text-align: center;
}
  .why-work-with-us .card-title{
    font-family: Inter-Regular;
    font-size: 20px;
    font-weight: 700;
    line-height: 29.3px;
    text-align: center;
    color: #6BB8FF;
  }
  .why-work-with-us{
    position: relative;
  }
  .why-work-with-us::before{
    content: url(../../Assets/images/why-work.svg);
    position: absolute;
    left: -50%;
    top: -200px;
  } 
  .why-work-with-us::after{
    content: url(../../Assets/images/asteroids.svg);
        position: absolute;
        right: 0%;
        top: 159px;
        opacity:0.4;
    }
    .lead:nth-child(2){
        font-family: Inter-Regular;
font-size: 14px;
font-style: italic;
font-weight: 300;
line-height: 20.51px;
text-align: center;

    }
    .lead{
        font-family: Inter-Regular;
font-size: 14px;
font-weight: 300;
line-height: 20.51px;
text-align: center;

    }
    .lead:nth-child(2){
        font-family: Inter-Regular;
font-size: 14px;
font-style: italic;
font-weight: 300;
line-height: 20.51px;
text-align: center;

    }
.lead-heading{
    font-size: 18px;
}
@media (max-width:769px) {
  .lead:nth-child(2) {
    margin-bottom: 9px;
  }.lead {
    margin-bottom: 0px;
  }
  .text-center.mt-5.why-disc h3 {
    padding-top: 11px;
    font-size: 20px;
}
.why-work-with-us .container:after {
  transform: scale(0.9);
}
.service-box p {
  font-size: 12px;
}
.service-box {
  padding: 10px !important;
  min-height: 250px !important;
}
.service-box img {
  height: auto;
  width: 54px;
}

}
@media (max-width:425px) {

.why-work-with-us .card-title{
font-size: 11px;
line-height: 16px;
}

.why-work-with-us .card {
min-height: 150px;
}

.why-work-with-us p.card-text {
  font-size: 10px;
  text-align: center;
}

.why-work-with-us h2 {
  padding-bottom: 10px;
  font-size: 20px;
}
.why-work-with-us .container::after{
  display: none;
}
.why-work-with-us::before{
  display: none;
}
.why-work-with-us::after{
  display: none;
}
.lead-heading {
  font-size: 16px;
}
.text-center.mt-5.why-disc h3 {
  font-size: 14px;
  line-height: 19px;
  padding-top: 3px;
}
.text-center.mt-5.why-disc {
  margin-top: 0px !important;
  background: url('../../Assets/images/partner-mobile-bg.svg') no-repeat center center / contain;
}
.text-center.mt-5.why-disc p {
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 1px;
  padding-top: 4px;
}
.services-section {
  padding: 20px 0px !important;
}
.services-section{
  padding: 20px 0px !important;
}

}