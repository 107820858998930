.hero-container {
    background: transparent; /* Dark background */
    color: #fff;
    padding: 50px 0;
    min-height: 700px;
  }
  
  .hero-content {
    padding: 20px;
    padding-top: 90px;
}
  
  .hero-content h1 {
    font-size: 32px;
    margin-bottom: 20px;
}
  
.hero-content p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 10px;
}
  
.get-quote-btn {
  position: relative;
  z-index: 99;
    padding: 7px 24px;
    margin-right: 15px;
    font-size: 1rem;
  }

  .learn-more-link {
    display: block;
    font-size: 1rem;
    color: #fff;
    margin-left: 0px;
    margin-top: 5px;
}
  
  .hero-image {
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
  }
  
  .earth {
    max-width: 480px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    animation: rotateEarth 30s infinite linear;
    z-index: 99;
    top: -100px;
    position: relative;
  }
  .hero-image::after{
   content: url('../../Assets/images/after-earth.svg');
   top: 139px;
   position: absolute;
   left: -198px;
  }
  .satellite {
    left: 79px;
    max-width: 150px;
    position: absolute;
    top: -162px;
    width: 100%;
}
  .hero-section {
    background: transparent;

}
.partner h2{
  text-align: center;
  color: #fff;
  padding-bottom: 50px;
}

.asteroid {
  position: absolute;
  left: -781px;
  top: 353px;
}
.hero-para{
  padding-bottom: 30px;
} 
@media (min-width: 1880px) and (max-width: 2560px) {

  .earth {
    animation: rotateEarth 30s linear infinite;
    max-width: 480px;
    top: -340px;
    width: 100%;
    z-index: 99;
}
.satellite {
    left: 235px;
    max-width: 150px;
    position: absolute;
    top: -383px;
    width: 100%;
}
}


@media (max-width:769px) {
  .hero-content {
    padding: 20px;
    padding-top: 0px;
}



.earth {
  animation: rotateEarth 30s linear infinite;
  max-width: 480px;
  top: -340px;
  width: 100%;
  z-index: 99;
}
.satellite {
  left: 125px;
  max-width: 150px;
  position: absolute;
  top: -383px;
  width: 100%;
}

}
@media (max-width:425px) {
  .hero-content{
    padding-top: 0px;
  }
  .hero-section {
    padding: 0px 7px;
    background: transparent;
}
  .hero-content h1 {
    max-width: 180px;
    font-size: 22px;
    margin-bottom: 20px;
}
.hero-content p{
  font-size: 14px;
}
.hero-container {
  background: transparent;
  color: #fff;
  min-height: 469px;
  padding-top: 50px;
  padding-bottom: 0px;
}
.earth {
  position: absolute;
  top: -488px;
  left: 160px;
  max-width: 149px !important;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  animation: rotateEarth 30s infinite linear;
  z-index: 99;
}
.satellite {
  left: 225px;
  max-width: 75px;
  position: absolute;
  top: -695px;
  width: 100%;
}
.key-point p{
  font-size: 8px !important;
  max-width: 100px;
}



}

  