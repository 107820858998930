.before-section {
    height: 900;
    margin-top: 100px;
    max-width: 1380px;
    margin: 0 auto;
min-height: 1250px;
overflow: hidden;
}
.earth-wrapper{
    position: relative;
}
.earth-wrapper .earth2 {
    position: absolute !important;
    top: 276px !important;
    left: 531px !important;
    z-index: 9;
}
.key-satelite{
    position: absolute;
    left: 50% ;
}
.point-3 {
    max-width: 340px;
    position: absolute;
    left: 64% !important;
    top: 21% !important;
}
.before-text {
    z-index: 0 !important;
}
.key-point.point-2 {
    max-width: 340px;
    top: 39%;
    left: 14%;
}
.key-point.point-2 p::after{
    content: "";
    height: 24px;
    width: 24px;
    top: 40px;
    right: 28px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
}
.key-point.point-1 p::after {
    content: "";
    height: 24px;
    width: 24px;
    top: 49px;
    right: 4px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
}
.key-point.point-3 p::before{
    content: "";
    height: 24px;
    width: 24px;
    left: -24px;
background-color: #fff;
border-radius: 50%;
position: absolute;
}
.key-point.point-4 p::before{
    content: "";
    height: 24px;
    width: 24px;
    left: -10px;
background-color: #fff;
border-radius: 50%;
position: absolute;
}
.key-point.point-4 {
    max-width: 340px;
    top: 57%;
    left: 67%;
}
.key-point.point-1 {
    max-width: 340px;
    left: 3%;
    top: 31%;
}
p.subdiscription {
    max-width: 800px;
    margin: 0 auto;
}
.globe-point-wrapper{
    margin-top: 00px;
    margin-bottom: 200px;
}
.pin-spacer {
    max-height: 1250px !important;
    overflow: hidden !important;
}
@media (max-width:1440px) {
    .earth-wrapper .earth2 {
        position: absolute !important;
        top: 276px !important;
        left: 533px !important;
        z-index: 9;
    }

}

@media (max-width:769px) {
    section.before-section {
        display: none;
    }
}