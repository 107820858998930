*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Inter-Medium;
  src: url(./Assets/Fonts/static/Inter_18pt-Medium.ttf);
}
@font-face {
  font-family: Inter-Regular;
  src: url(./Assets/Fonts/static/Inter_18pt-Regular.ttf);
}
.hero-wrapper{
  position: relative;
background: url(./Assets/images/Home-bg.svg) no-repeat top center / 100%;
background-color: #000;
}
.hero-wrapper::after{
  content: url(./Assets/images/home-eclips.svg);
  position: absolute;
  top: 110px;
  left: 10%;
}
.text-center.mt-5.why-disc {
  padding:40px 0px;
  background: url('./Assets/images/Cta-bg.svg') no-repeat center center / contain;
  margin: 0 auto;
}
p{
  font-family: Inter-Regular;
}
div#root {
  overflow: hidden;
}
html {
  scroll-behavior: smooth;
}
.heading{
  font-family: Inter-Medium;
font-size: 24px;
font-weight: 700;
line-height: 35.16px;
text-align: center;
color: #fff;
}
.btn-primary {
  background-color: #2B8BE4 !important;
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}
.card-body {
  --bs-card-border-color: transparent; /* or you can use none */
}               
@media (max-width:769px) {
  .container, .container-sm {
    max-width: 700px !important;
}
.hero-content h1 {
  font-size: 30px;
  margin-bottom: 20px;
}
}