.video-section .card {
    background: url('../../Assets/images/card-founder.svg') no-repeat center center / contain;
    color: #fff;
    min-height: 650px;
    max-height: 500px;
    max-width: 369px;
    margin: 0 auto;
}

.video-section .card video {
    min-height: 250px;
    width: 100%;
    border-radius: 8px;
}

.video-section .card h5 {
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
}

.video-section .card p {
    text-align: left;
    color: #fff;
}

.video-section h5.card-title {
    text-align: left;
    color: #6BB8FF;
}

.video-section p.card-subtitle.mb-2 {
    text-align: left;
    color: #fff;
}
.video-section{
    position: relative;
}
.video-section .container {
    background-size: cover;
    background-position: center;
}

.video-section::after {
    content: url('../../Assets/images/home-eclips.svg');
    position: absolute;
    bottom: -60%;
    left: -383px;

}
.video-section .card{
    position: relative;
    z-index: 99;
}
.video-section .card-body{
    padding-top: 0px;
}
.video-section .card p {
    min-height: 190px;
    font-size: 11px;
    text-align: left;
    color: #fff;
}
@media (max-width:769px) {
    .video-section .card-body {
        min-height: 99px;
        padding-top: 0;
        position: relative;
        top: 8px;
    }
    .video-section .card p{
        min-height: auto !important;
        font-size: 11px;
    }
}
@media (max-width:425px) {
    .video-card-2 ,.video-card-3{
        margin-top: -117px !important;
    }
}