.testimonial-swiper {
    padding: 20px;
}

.testimonial-swiper  .swiper-wrapper {
    padding-bottom: 51px;
}
.testimonial-card {

    background: url(../../Assets/images/testiomonial-bg.svg) no-repeat center center / contain;
    min-height: 585px;
    color: white;
    padding: 30px;
    border-radius: 10px;
    height: 100%;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.testimonial-body p {
    padding: 0px 7px;
    font-size: 14px;
    min-height: 335px;
}
.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    display: none;
}
.swiper-button-prev {
    top: 95%;
    left: 93%;
}
.swiper-button-next {
    top: 95%;
}
.swiper-button-next:after {
    font-size: 19px;
}
.swiper-button-prev:after {
    font-size: 19px;
}
.testimonial-header {
    display: flex;
    align-items: center;
}

.testimonial-wrapper::after{
    content: url(../../Assets/images/luc-testi-bg.svg);
    top: -20%;
    right: 0px;
    position: absolute;
    
}

.testimonial-wrapper::before{
    content: url(../../Assets/images/asteroid3.svg);
    position: absolute;
    left: 0px;
    bottom: 5%;
}

.profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

.header-text h3 {
    margin: 0;
    font-size: 1.2rem;
}

.header-text p {
    margin: 5px 0 0;
    font-size: 0.9rem;
}

.rating {
    font-size: 1rem;
    color: #f5c518;
    margin-top: 5px;
}

.testimonial-body {
    margin-top: 15px;
    flex-grow: 0.5;
}

.testimonial-footer {
    margin-top: 20px;
    font-size: 0.8rem;
    opacity: 0.6;
}

/* Swiper Navigation Arrows */
.swiper-button-next, .swiper-button-prev {
    color: white;
}

/* Swiper Pagination */
.swiper-pagination-bullet {
    background: white;
}
.testimonial-wrapper {
    margin-top: -85px;
    position:relative;
    z-index: 9999;
    background-color: #000;
}
@media (max-width:769px) {
    .testimonial-card {
        height:auto;
        max-height: 311px;
        min-height: 311px;
        padding: 8px 15px 18px;
    }

    .testimonial-body p {
        font-size: 8px;
        min-height: 117px;
    }
    .testimonial-body {
        flex-grow: 0.5;
        margin-top: 5px;
    }
    .testimonial-card .header-text p {
        font-size: 10px;
        margin: 5px 0 0;
    }
    .swiper-button-prev {
        left: 89%;
        top: 95%;
    }
}
@media (max-width:425px) {
    .testimonial-body p {
        font-size: 9px;
    }
    .testimonial-card {
        padding: 18px 10px;
        min-height: 332px;
    }
    .header-text h3 {
        margin: 0;
        font-size: 16px;
    }
    .testimonial-body {
        max-height: 200px;
        margin-top: 6px;
        flex-grow: 0.5;
    }
    .testimonial-card {
        padding: 10px;
    }
    .testimonial-body {
        max-height: 200px;
        margin-top: 6px;
        flex-grow: 0.5;
    }
    .testimonial-body p {
        min-height: 200px;
        margin-bottom: 0px;
    }
    .testimonial-footer{
        margin-top: 0px;
    }
    .testimonial-wrapper {
        margin-top: -175px;
    }
}