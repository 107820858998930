.after-mob-img{
    display: none;
}
@media (max-width:769px) {

    .after-mob-img{
        color: #fff;
        padding: 20px 0px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .after-mob-img p{
        font-size: 12px;
        padding: 0px 10px;
        text-align: center;
    }
    .after-mob-img img {

        margin: 0 auto;
        max-width: 341px !important;
    }
    .after-mob-img p {
        max-width: 600px;
        font-size: 12px;
        padding: 0 10px;
        text-align: center;
      }
}