.commitment-section {

    background-size: cover;
    padding: 150px 0;
    padding-bottom: 20px;
  }
  
  .commitment-section .card {
    padding: 40px 30px;
    background: url('../../Assets/images/Cta-bg.svg') no-repeat center center/ contain;
    border-radius: 15px;
    max-width: 1000px;
  }
  .commitment-section .btn{
    max-width: 200px;
    padding: 5px 35px !important;
    margin: 0 auto;
  }

  .card h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .commitment-section .card p {
    font-family:Inter-Regular;
font-size: 18px;
font-weight: 400;
line-height: 30.77px;
text-align: center;

  }
  
  .card .btn {
    padding: 10px 20px;
    font-size: 16px;
  }
  .bootom-hr{
    border: 1.5px solid #fff;
  }
  .bottom-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }
  .bottom-footer a{
    color: #fff;
    text-decoration: none;
  }
  .social-links{
    display: flex;
    gap: 15px;
  }
  .social-links img{
    width: 40px;
  }


  @media (max-width:768px) {
    .commitment-section .card h2 {
      font-size: 23px;
      margin-bottom: 7px;
  }
  .commitment-section .card p {
    font-family: Inter-Regular;
    font-size: 15px;
    margin-bottom: 8px !important;
  }
}
  @media (max-width:425px) {
    .commitment-section .card{
      background:url('../../Assets/images/bottom-cta-bg.svg') no-repeat center center / contain !important;
    }
    .card h2 {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .commitment-section .card p {
      font-size: 11px;
      line-height: 13.77px;
      margin-bottom: 10px !important;
    }
    .commitment-section .btn {
      padding: 5px 23px !important;
      font-size: 10px !important;
    }
    .bootom-hr {
      margin-bottom: 0px !important;
      margin-top: 10px;
    }
    .commitment-section {
      padding: 46px 0;
      padding-bottom: 10px;
  }
    .social-links img {
      width: 25px;
    }
    .development-company {
      font-size: 9px;
  }
  .commitment-section .card h2 {
    font-size: 14px;
    margin-bottom: 7px;
}
hr.mt-5.bottom-hr {
  margin-top: 0px !important;
}
  }