.plans-section {
    z-index: 99;
    position: relative;
    margin: 0 auto;
    max-width: 1200px;
    background-size: cover;
    padding: 50px 0;
}
  
  .plan-card {
 background: url(../../Assets/images/pricing-bg.svg) no-repeat center center / contain;
 min-height: 650px;
    border-radius: 15px;
    padding: 20px;
  }
.plan-card-1 {
    border-radius: 36px;
    max-width: 83%;
    margin-top: 30px;
    padding: 20px -1px;
    min-height: 492px;
    max-height: 574px;
}
.plan-card-3 {
  border-radius: 36px;
  max-width: 83%;
  margin-top: 30px;
  padding: 20px -1px;
  min-height: 492px;
}
.plans-section .col-lg-4.col-md-6.mb-4 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.last-card{
justify-content: flex-start !important;
}
  .plan-card h3 {
    font-family: Inter-Medium;
    font-size: 30px;
    font-weight: 400;
    line-height: 43.95px;
    text-align: center;
color: #fff;    
padding-bottom: 40px;
  }
  
  p.sub-title{
    font-family: Inter-Regular;
font-size: 18px;
font-weight: 700;
line-height: 28.51px;
text-align: center;
color: #6BB8FF;
min-height: 110px;
  }
  .plan-card ul {
    text-align: left;
    list-style-type: disc;
    padding: 0;
    font-family: Inter-Regular;
    font-size: 13px;
    font-weight: 500;
    line-height: 20.65px;
    min-height: 200px;
  }
  .plan-card .card-body {
    display: flex;
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color);
    flex-direction: column;
    justify-content: space-evenly;
}
  .plan-card ul li {
    color: #fff;
    margin-bottom: 10px;
  }
  
  .plan-card .btn {
    padding: 7px 24px;
    margin: 0 auto;
    width: max-content;
    background: #2B8BE4;
    margin-top: 20px;
}
.plans-section::before{
content: url(../../Assets/images/earth.png);
position: absolute;
left: -20%;
bottom: -21%;

}
.plans-section::after{
content: url(../../Assets/images/bottom-svg-footer.svg);
position: absolute;
right: 0%;
bottom: -29%;
z-index: -1;
}
.first-price{
  display: flex;
  justify-content: flex-end !important;
}
section.plans-section.text-white h2 {
  margin-bottom: 10px !important;
}
@media (max-width:769px) {
  p.sub-title {
    font-family: Inter-Regular;
    font-size: 12px;
    font-weight: 700;
    line-height: 15.51px;
    text-align: center;
    color: #6BB8FF;
    min-height: 0px;
    margin-bottom: 0px;
}
.plan-card h3 {
  font-family: Inter-Medium;
  font-size: 20px;
  padding-bottom: 0px;
}
.plan-card ul li {
  line-height: 19px;
  font-size: 9px;
  color: #fff;
  margin-bottom: 5px;
}
.plan-card ul{
  padding-top: 10px;
}
.plan-card-1 {
  border-radius: 36px;
  max-width: 100%;
  margin-top: 30px;
  padding: 20px -1px;
  min-height: 465px;
  max-height: 469px;
}
.plan-card .btn {
  padding: 7px 24px;
  margin: 0 auto;
  width: max-content;
  background: #2B8BE4;
  margin-top: 20px;
  font-size: 11px !important;
  padding: 5px 20px !important;
}
.plan-card {
  min-height: 457px;
}
.plan-card .card-body {
  padding: 5px !important;
}
.plan-card ul {
  min-height: 136px;
}
.card.plan-card.plan-card-2 {
  min-height: 467px;
}
.plan-card-3{
  min-width: 100% !important;
}
.plans-section::before {
  transform: scale(0.5);
  left: -36%;
  top: 46%;
}
}


@media (max-width:425px) {
  .plans-section .col-lg-4.col-md-6.mb-4 {
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-body {
  padding: 0px 10px !important;
}
.plan-card-1 {
  margin-top: 0px;
  border-radius: 36px;
  max-width: 100%;
}
.plan-card ul {
  min-height: 100px;
}
.plan-card {
  min-height: 573px;
}
.last-card{
  justify-content: center !important;
}
.plan-card {
  padding: 0 45px;
}
.plan-card h3{
  padding-bottom: 30px;
  font-size: 27px;
}
.card-body {
  justify-content: center !important;
  padding: 0px 10px !important;
}
section.plans-section.text-white h2 {
  margin-bottom: 15px !important;
}
p.sub-title {
  min-height: 80px;
}
.plans-section::before {
  bottom: -70%;
  left: -60%;
  transform: scale(0.4);
}
.plan-card-2 .card-body {
  padding: 0px 30px !important;
}
.plans-section::after {
  transform: scale(0.4);
  bottom: -15%;
  right: -233px;
}

.swiper-button-next {
  top: 95%;
  right: 0px;
}
.swiper-button-prev {
  top: 95%;
left: 85%;
}
.note {
  max-width: 350px;
  position: relative;
  z-index: 99;
  left: 10px;
  top: -5px;
}
.plan-card .card-body {
  padding: 16px !important;
}
.first-price {
  display: flex;
  justify-content: center !important;
}
.plan-card-3 {
  margin: 0 auto;
  min-width: 89% !important;
}
.card.plan-card.plan-card-2 {
  min-height: 540px;
}
}