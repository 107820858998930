

.Navbar{
font-family: inter-Regular;
}

.Navbar{
    font-family: Inter-Medium;
}
.navbar-expand-lg .navbar-nav{
    gap: 0px 30px;
}
.header-wrapper {
    background-color: transparent;
}
.Navbar {
    border-bottom: 0.5px solid #ffffff92;
    background: transparent !important;
    font-family: Inter-Medium;

}