.before-img{
    display: none;
}
@media (max-width:769px) {
    .before-img {
        color: #fff;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 30px 0px;
    }
    .before-img img {
        margin: 0 auto;
        max-width: 341px !important;
    }
    .before-img p{
     font-size: 12px;
     text-align: center;
     align-items: center;
     max-width: 600px;
    }
}