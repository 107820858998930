.globe-container {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    z-index: 9;
  }
  
  .globe-img {
    position: relative;
    width: 100%;
    height: auto;
    display: block;
  }
  .globe-img::after{
    content: url(../../Assets/images/center-eclip.svg);
    left: 50%;
    top: 50%;
    position: absolute;
    height: 500px;
    width: 500px;
    transform: scale(1.5);
  }
  img.stars {
    transform: rotate(45deg);
    right: -173px;
    top: -139px;
    position: absolute;
}
img.shine {
  max-width: 1010px;
  top: -235px;
  position: absolute;
  left: -43px;
  z-index: -1;
}
  .globe-img::before{
    content: url("../../Assets/images/globe-star.svg");
    left: 50%;
    top: 50%;
    position: absolute;
    height: 500px;
    width: 500px;
    transform: scale(1.5);
  }
  
  .feature {
    max-width: 250px;
    position: absolute;
    font-family: Inter-Regular;
    font-size: 20px;
    font-weight: 700;
    line-height: 29.3px;  
    text-align: center;
    color: #6BB8FF;
    
  }
  
  .feature-left {
    left: 0;
  }
  
  .feature-right {
    right: 0;
  }
  
  .top-left {
    max-width: 200px;
    top: 10%;
    left: -97px;
}
  .top-left::after{
    content: url('../../Assets/images/top-left.svg');
    top: 33px;
    left: 163px;
    position: absolute;
  }
  
  .mid-left {
    top: 50%;
    left: -97px;
    max-width: 200px;
}
  .mid-left::after{
    content: url('../../Assets/images/middle-left.svg');
    left: 163px;
    top: 25%;
    position: absolute;
  }
  
  .bottom-left {
    top: 88%;
    left: -97px;
    max-width: 200px;
}
  .bottom-left::after {
    content: url('../../Assets/images/bottom-left.svg');
    top: -56px;
    left: 100%;
    position: absolute;
  }
  
  .top-right {
    top: 10%;
    right: -164px;
}
  .top-right::after {
    content: url('../../Assets/images/top-left.svg');
    transform: scaleX(-1);
    top: 26%;
    left: -126px;
    position: absolute;
  }
  .mid-right::after {
    content: url('../../Assets/images/middle-left.svg');
    top: 18%;
    left: -104px;
    position: absolute;
    transform: scaleX(-1);
}
  
.mid-right {
  top: 51%;
  right: -21%;
}
  
.bottom-right {
  bottom: -3%;
  right: -22%;
}
  
  .bottom-right::after{
    content: url('../../Assets/images/bottom-left.svg');
    transform: scaleX(-1);
    right: 111%;
    position: absolute;
    bottom: 38%;
}
.call-now-btn {
  position: relative;
  z-index: 99;
  background-color: #007bff;
  border: none;
  font-size: 18px;
  padding: 7px 20px;
}
  .globe-img{
    max-width: 500px;
    margin: 0 auto;
  }
  .feature p{
    opacity: 1 !important; 
  }
  .feature{
    opacity: 1;
  }
  .mob-wsa {
    display: none;
  }
  @media (max-width: 769px) {
    .feature {
      font-size: 14px;
      padding: 5px;
    }
  
    .top-left, .mid-left, .bottom-left, .top-right, .mid-right, .bottom-right {
      position: static;
      margin-bottom: 10px;
    }
  
    .globe-container {
      text-align: center;
    }

    .desktop-wsa{
      display: none;
    }
    .mob-wsa {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .mob-wsa img {
    max-width: 542px;
}
  }
  @media (max-width: 425px) {
    .desktop-wsa{
      display: none;
    }
    .mob-wsa {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .mob-wsa img {
    max-width: 342px;
}
.globe-wsa h2{
font-size: 15px;
}
.globe-wsa h4{
font-size: 14px;
padding-bottom: 10px !important;
}
.globe-wsa p{
  font-size: 13px;
}
.globe-wsa .btn{
  font-size: 12px  !important;
}
.plans-section{
  padding-top: 10px !important;
}
.earth{
  transform: translate(27px, -208px);
}
  }