.services-section {
    color: #fff;
    position: relative;
  }
  
  .services-section::after{
    content: url(../../Assets/images/stars.svg);
    position: absolute;
    left: 40%;
    top: -50px;
  }
  .service-box::after{
    content: url('../../Assets/images/center-eclip.svg');
    position: absolute;
    left: 180%;
    top:-300%;
    transform: scale(0.9);
      }
  .service-box::before{
    content: url('../../Assets/images/center-eclip.svg');
    position: absolute;
    right: 180%;
    top:-200%;
    transform: scale(0.9);
      }
  .service-box img {
    width: 80px;
    height: auto;

  }
  .service-box{
    will-change: transform, opacity; 
    position: relative;
    padding: 20px;
    border: 1px solid #fff;
    border-radius: 30px;
    max-width: 332px;
    min-height: 325px;
    margin: 0 auto;
  }
  h5 {
    color: #00baff;
  }
  .line {
    display: block;
    border-bottom: 1px solid #fff;
    width: 100%;
    height: 1px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
}

@media (max-width:425px) {
  .service-box{
    margin: 0 auto;
    max-width: 300px;
    min-height: 282px;
  }
  .video-section .card{
    margin:0px ;
    min-height: 500px;

  }
  .video-section     .card-body {
    padding: 0px 15px !important;
}
.video-section .col-md-4{
  min-height: 560px;
  max-height: 560px;
  overflow: hidden;
}
section.services-section.py-5 p {
  font-size: 11px;
}
}