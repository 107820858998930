.second-section {
    padding: 40px 0;
  }
  
  .company-card {
    border-radius: 10px;
    padding: 0px;

    color: white;
    transition: transform 0.3s ease;
  }
  
  .company-card:hover {
    transform: translateY(-10px);
  }
  
  .company-logo {
    width: 100%;
    height: auto;
}
  
  .company-card h5 {
    color: #fff;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .company-card p {
    font-size: 12px;
    color: #fff;
  }
  
  .mySwiper {
    padding: 40px 25px;
    background: url(../../Assets/images/partner-bg.svg) no-repeat center center / contain;
    z-index: 999;
  }
  .partener-discription h3{
    font-family: Inter-Medium;
font-size: 24px;
font-weight: 700;
line-height: 35.16px;
text-align: center;
color: #fff;

  }
  .partener-discription{
    position: relative;
    padding: 40px 0px;
  }
  .partener-discription p{
    font-family: Inter-Regular;
font-size: 16px;
font-weight: 500;
line-height: 23.44px;
text-align: center;
color: #fff;
  }

   .partener-discription::after{
    content: url('../../Assets/images/out-grow-bg.svg');
    position: absolute;
    top: 0px;
    transform: scale(1.5);
    left: 40%;
   }
 @media (max-width:769px) {
  .company-logo {
    width: 30%;
    height: auto;
    margin-bottom: 0px !important;
}
.company-card p {
  font-size: 9px;
  color: #fff;
}
.company-card:hover {
  transform: translateY(0px);
}
.company-card {
  border-radius: 10px;
  padding-top: 25px;
}
.second-section .mySwiper {
  padding: 4px 10px;
}
.company-card h5 {
  color: #fff;
  font-size: 13px;
  margin-top: 6px;
}
.second-section  .swiper-button-next, .second-section .swiper-button-prev{
  display: none; 
}
.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
  max-width: 600px;
}
 }

@media (max-width:425px) {
  .company-card h5 {
    color: #fff;
    font-size: 8px;
    margin-bottom: 0px;
    margin-top: -6px;
}
.company-card p {
  font-size: 6px;
  color: #fff;
}
.company-card img{
  max-width: 35px;
}
.partner .mySwiper {
  padding: 6px 6px;
  padding-top: 25px;
  background: url('../../Assets/images/partner-mobile-bg.svg') no-repeat center center / contain;
}
.partner .swiper-button-next, .partner  .swiper-button-prev {
  display: none;
}
.company-card img {
  margin-bottom: 9px !important;
  max-width: 50px;
}
.partner h2 {
  font-size: 20px;
  padding-bottom: 10px;
}
.second-section {
  padding: 0px 20px;
}
.company-card:hover{
 transform: translateY(0px) ;
}
.partener-discription p {
  font-family: Inter-Regular;
  font-size: 12px;
}
.partener-discription h3 {
  font-size: 14px;
  line-height: 19.16px;
  margin: 10px 0px;
}

.why-work-with-us {
  padding: 10px 0px !important;
}
.why-work-with-us h2 {
  padding-bottom: 30px;
  font-size: 24px;
}
.card-body{
  padding: 0px 0px !important;
}
.why-work-with-us .card {
  z-index: 99;
  position: relative;
  min-height: 218px;
}
.partener-discription {
  position: relative;
  padding: 39px 0px;
  padding-bottom: 10px;
}
.why-work-with-us .container::before{
  display:none;
}
.company-logo {
  width: 64% !important;
  height: auto;
  margin-bottom: 0px !important;
}
.company-card {
  border-radius: 10px;
  padding-top: 0px !important;
}
}
